import React from 'react';
import './App.css';
import logo from './electro.png';
import Button from '@mui/material/Button';

function App() {
  return (
    <div className="App">
      <div className="App">
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Welcome to Electrotech.app</h1>
        <p>Real time production tracking app.</p>
        <p>Follow the production status of your industrial control panels.</p>
        <Button variant="contained" color="primary" className="continue-button">
          Request Access
        </Button>
        </header>
      </div>
    </div>
  );
}

export default App;
